@import '_mixin.scss';
@import '_mobile.scss';

.contentresan-container,
#contentresan-container {
  background-image: url(../images/backgrounds/second-background.png);
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  width: 100%;
}

/*Project title*/
.project-container {
  margin: 30px;
  padding: 30px;
}

/*Post it notes*/

// .fa-trash-alt:hover + .note-buttons {
//   color: red !important;
// }

.postit {
  position: relative;
  width: 200px;
  height: 200px;
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
  background-color: bisque;

  p {
    margin: 0;
    padding: 5px;
    word-break: break-all;
  }
  &:hover {
    opacity: 1 !important;
  }

  button {
    margin: 2px;
    // border: none;
    background: rgba(255, 255, 255, 0.5);
    color: rgb(0, 0, 0);
  }
}

//Delete buttons

// .postit:hover > span {
//   opacity: 1 !important;
// }

// .postit > span {
//   position: absolute;
//   bottom: 2px;
//   right: 2px;
//   opacity: 0 !important;
//   transition: opacity 0.25s linear;
// }

.postit > textarea {
  height: 75%;
  width: 96%;
  background: transparent;
  border: transparent;
  resize: none;

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }
}

textarea::placeholder {
  color: $defaultTextColor;
}

.green-postit.postit {
  @include greenPostIt;
  top: -10em;
  left: -21em;
}

.blue-postit {
  @include bluePostIt;
  top: -19em;
  left: -17em;
}

.orange-postit {
  @include orangePostIt;
  bottom: 9em;
  left: 10em;
}

.yellow-postit {
  @include yellowPostIt;
  right: -10em;
}

.darkblue-postit {
  @include darkbluePostIt;
  left: -3em;
  bottom: -5em;
}

.trigger-postit {
  @include triggerPostIt;
  margin-left: 400px;
  right: 39em;
  bottom: -3.7em;
}

// Make the background fill out whole bottom of page
.clear {
  padding: 271px;
}

footer {
  padding: 10px;
  text-align: right;
}

/*Popup box*/
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);

  .popup-bar {
    padding: 11px 37px 11px 24px;

    .close {
      color: rgb(255, 255, 255);
      opacity: 1;
      font-size: 3em;
      &:hover {
        color: rgb(255, 255, 255);
        opacity: 1;
      }
    }
  }

  .popup-container {
    padding: 20px 24px 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  @import '_mobile.scss';
}
