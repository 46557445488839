@import '_mixin.scss';
@import '_desktop.scss';
@import '_mobile.scss';

/*GENERAL STYLING*/

body,
html {
  margin: 0px;
  padding: 0px;
  font-family: $roboto;
  font-weight: 400; //regular
}

/*HOME PAGE*/

.loading-page {
  text-align: center;
  background-image: url('../images/backgrounds/startpage-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  .loading-spinner {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }
}

.chiffer-logo-black {
  height: 10vmin;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App {
  text-align: center;
  background-image: url('../images/backgrounds/startpage-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
//form
.form-container {
  margin: 20px;
  padding: 20px;
}

.form-group {
  margin: 40px;
  color: $defaultTextColor;

  label {
    font-size: 1.5rem;
    font-weight: 500;
  }

  input {
    background-color: transparent;
    border-bottom: 1px solid $defaultTextColor !important;
    outline: 0;
    border: 0;
    border-radius: 0;
    color: $defaultTextColor;

    &:focus {
      background-color: transparent;
      outline: none;
      box-shadow: none;
      color: $defaultTextColor;
    }

    &:select {
      border: 0;
      background-color: transparent !important;
    }
  }

  .start-button {
    margin: 20px;
    background-color: $chifferTurquoise;
    border-color: #34a3a9;

    .button-arrow {
      margin: 4px;
      padding: 4px;
      font-size: 1.2rem;
    }

    &:hover {
      background-color: #34a3a9;
    }
  }
}

.text-danger {
  font-size: 1.2rem;
  font-weight: bold;
}

/*NAVBAR*/

.navbar-brand {
  cursor: default;
  .chiffer-logo-black {
    height: 4.5vmin;
  }
}

.navbar {
  background-color: $navbarColor;
  font-weight: 300; //light font-weight
  letter-spacing: 2px;
  word-spacing: 2px;
}

.nav-item {
  margin: 10px;

  &:hover {
    cursor: pointer;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: #939ca4;

  &:hover {
    color: $defaultWhite;
  }
}

.vertical-align-middle {
  vertical-align: middle; //Keeps the icons the same line as text
}

.padding-bottom-3 {
  padding-bottom: 3px; //Keeps the icons the same line as text
}

/*DESKTOP*/

@media screen and (min-width: 768px) {
  @import '_desktop.scss';
}

/*MOBILE*/

@media screen and (max-width: 768px) {
  @import '_mobile.scss';
}
