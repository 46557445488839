// fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400, 400i,500,500i,700,700i,900,900i');

//font variables
$roboto: 'Roboto', sans-serif;

//colors
$chifferTurquoise: rgb(89, 169, 173);
$defaultTextColor: rgb(0, 0, 0);
$defaultWhite: #ffff;
$navbarColor: #364856;
$dropdownColor: #426885;
$greyLineColor: #979797;

// post it colors

@mixin greenPostIt {
  background-color: #71be41 !important;
  border-top: 40px solid #4d9720 !important;
}

@mixin bluePostIt {
  background-color: #52a8fa;
  border-top: 40px solid #1c8df8;
}

@mixin orangePostIt {
  background-color: #f4901b;
  border-top: 40px solid #d4790e;
}

@mixin yellowPostIt {
  background-color: #fadc02;
  border-top: 40px solid #c5b000;
}

@mixin darkbluePostIt {
  background-color: #54758a;
  border-top: 40px solid #496578;
}

@mixin triggerPostIt {
  background-color: white;
  border: 5px dotted #e05720;
}
