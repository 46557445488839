@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400, 400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400, 400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400, 400i,500,500i,700,700i,900,900i);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dropdown-item {
  width: 16%; }

.dropdown-item {
  width: 100%; }

footer {
  padding: 10px;
  text-align: center; }

/*GENERAL STYLING*/
body,
html {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto", sans-serif;
  font-weight: 400; }

/*HOME PAGE*/
.loading-page {
  text-align: center;
  background-image: url(/static/media/startpage-background.fdc14839.jpg);
  background-repeat: no-repeat;
  background-size: cover; }
  .loading-page .loading-spinner {
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin); }

.chiffer-logo-black {
  height: 10vmin; }

.App-header {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin); }

.App {
  text-align: center;
  background-image: url(/static/media/startpage-background.fdc14839.jpg);
  background-repeat: no-repeat;
  background-size: cover; }

.form-container {
  margin: 20px;
  padding: 20px; }

.form-group {
  margin: 40px;
  color: black; }
  .form-group label {
    font-size: 1.5rem;
    font-weight: 500; }
  .form-group input {
    background-color: transparent;
    border-bottom: 1px solid black !important;
    outline: 0;
    border: 0;
    border-radius: 0;
    color: black; }
    .form-group input:focus {
      background-color: transparent;
      outline: none;
      box-shadow: none;
      color: black; }
    .form-group input:select {
      border: 0;
      background-color: transparent !important; }
  .form-group .start-button {
    margin: 20px;
    background-color: #59a9ad;
    border-color: #34a3a9; }
    .form-group .start-button .button-arrow {
      margin: 4px;
      padding: 4px;
      font-size: 1.2rem; }
    .form-group .start-button:hover {
      background-color: #34a3a9; }

.text-danger {
  font-size: 1.2rem;
  font-weight: bold; }

/*NAVBAR*/
.navbar-brand {
  cursor: default; }
  .navbar-brand .chiffer-logo-black {
    height: 4.5vmin; }

.navbar {
  background-color: #364856;
  font-weight: 300;
  letter-spacing: 2px;
  word-spacing: 2px; }

.nav-item {
  margin: 10px; }
  .nav-item:hover {
    cursor: pointer; }

.navbar-light .navbar-nav .nav-link {
  color: #939ca4; }
  .navbar-light .navbar-nav .nav-link:hover {
    color: #ffff; }

.vertical-align-middle {
  vertical-align: middle; }

.padding-bottom-3 {
  padding-bottom: 3px; }

/*DESKTOP*/
@media screen and (min-width: 768px) {
  .dropdown-item {
    width: 16%; } }

/*MOBILE*/
@media screen and (max-width: 768px) {
  .dropdown-item {
    width: 100%; }
  footer {
    padding: 10px;
    text-align: center; } }

.dropdown-item {
  width: 100%; }

footer {
  padding: 10px;
  text-align: center; }

.contentresan-container,
#contentresan-container {
  background-image: url(/static/media/second-background.aeec07c4.png);
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  width: 100%; }

/*Project title*/
.project-container {
  margin: 30px;
  padding: 30px; }

/*Post it notes*/
.postit {
  position: relative;
  width: 200px;
  height: 200px;
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
  background-color: bisque; }
  .postit p {
    margin: 0;
    padding: 5px;
    word-break: break-all; }
  .postit:hover {
    opacity: 1 !important; }
  .postit button {
    margin: 2px;
    background: rgba(255, 255, 255, 0.5);
    color: black; }

.postit > textarea {
  height: 75%;
  width: 96%;
  background: transparent;
  border: transparent;
  resize: none; }
  .postit > textarea:hover {
    background: rgba(255, 255, 255, 0.5); }

textarea::-webkit-input-placeholder {
  color: black; }

textarea::-ms-input-placeholder {
  color: black; }

textarea::placeholder {
  color: black; }

.green-postit.postit {
  background-color: #71be41 !important;
  border-top: 40px solid #4d9720 !important;
  top: -10em;
  left: -21em; }

.blue-postit {
  background-color: #52a8fa;
  border-top: 40px solid #1c8df8;
  top: -19em;
  left: -17em; }

.orange-postit {
  background-color: #f4901b;
  border-top: 40px solid #d4790e;
  bottom: 9em;
  left: 10em; }

.yellow-postit {
  background-color: #fadc02;
  border-top: 40px solid #c5b000;
  right: -10em; }

.darkblue-postit {
  background-color: #54758a;
  border-top: 40px solid #496578;
  left: -3em;
  bottom: -5em; }

.trigger-postit {
  background-color: white;
  border: 5px dotted #e05720;
  margin-left: 400px;
  right: 39em;
  bottom: -3.7em; }

.clear {
  padding: 271px; }

footer {
  padding: 10px;
  text-align: right; }

/*Popup box*/
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5); }
  .popup .popup-bar {
    padding: 11px 37px 11px 24px; }
    .popup .popup-bar .close {
      color: white;
      opacity: 1;
      font-size: 3em; }
      .popup .popup-bar .close:hover {
        color: white;
        opacity: 1; }
  .popup .popup-container {
    padding: 20px 24px 30px; }
    .popup .popup-container img {
      width: 100%;
      height: 100%; }

@media screen and (max-width: 768px) {
  .dropdown-item {
    width: 100%; }
  footer {
    padding: 10px;
    text-align: center; } }

.circle-container {
  position: relative;
  width: 25em;
  height: 25em;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  box-sizing: content-box;
  margin: 17em auto 0;
  border: solid 10px black; }
  .circle-container li {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6em;
    height: 6em;
    margin: -3em; }
    .circle-container li img {
      display: block;
      width: 100%;
      border-radius: 50%; }

/*Lines conecting to the post it notes*/
.steg-ett::before {
  content: '';
  position: absolute;
  top: 2.9em;
  left: -10em;
  width: 10em;
  height: 0.2em;
  background: #979797; }

.steg-tva::before {
  content: '';
  position: absolute;
  top: 2em;
  left: -10.02em;
  width: 10.16em;
  height: 0.2em;
  background: #979797; }

.steg-tva::after {
  content: '';
  position: absolute;
  top: -0.6em;
  right: 16em;
  width: 0.2em;
  height: 2.8em;
  background: #979797; }

.steg-tre::before {
  content: '';
  position: absolute;
  top: 2em;
  right: -9.02em;
  width: 9.16em;
  height: 0.2em;
  background: #979797; }

.steg-fyra::before {
  content: '';
  position: absolute;
  top: 5.8em;
  right: 1.98em;
  width: 0.2em;
  height: 7.16em;
  background: #979797; }

.steg-fyra::after {
  content: '';
  position: absolute;
  top: 12.8em;
  left: 3.8em;
  width: 6.16em;
  height: 0.2em;
  background: #979797; }

.steg-fem::before {
  content: '';
  position: absolute;
  top: 5.8em;
  left: 3em;
  width: 0.2em;
  height: 5.16em;
  background: #979797; }

.steg-trigger::before {
  content: '';
  top: -3em;
  left: -8em;
  position: absolute;
  bottom: -6.3em;
  width: 8em;
  height: 6.16em;
  border-bottom: 5px dotted #e05720; }

.steg-trigger::after {
  content: '';
  top: 3em;
  left: -8em;
  position: absolute;
  width: 8em;
  height: 6.16em;
  border-left: 5px dotted #e05720; }

.circle-container > :nth-child(4) {
  -webkit-transform: rotate(30deg) translate(12em) rotate(-30deg);
          transform: rotate(30deg) translate(12em) rotate(-30deg); }

.circle-container > :nth-child(5) {
  -webkit-transform: rotate(90deg) translate(12em) rotate(-90deg);
          transform: rotate(90deg) translate(12em) rotate(-90deg); }

.circle-container > :nth-child(6) {
  -webkit-transform: rotate(150deg) translate(12em) rotate(-150deg);
          transform: rotate(150deg) translate(12em) rotate(-150deg); }

.circle-container > :nth-child(1) {
  -webkit-transform: rotate(210deg) translate(12em) rotate(-210deg);
          transform: rotate(210deg) translate(12em) rotate(-210deg); }

.circle-container > :nth-child(2) {
  -webkit-transform: rotate(270deg) translate(12em) rotate(-270deg);
          transform: rotate(270deg) translate(12em) rotate(-270deg); }

.circle-container > :nth-child(3) {
  -webkit-transform: rotate(330deg) translate(12em) rotate(-330deg);
          transform: rotate(330deg) translate(12em) rotate(-330deg); }

