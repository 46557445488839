@import '_mixin.scss';

.circle-container {
  position: relative;
  width: 25em;
  height: 25em;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  box-sizing: content-box;
  margin: 17em auto 0;
  border: solid 10px black;

  li {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6em;
    height: 6em;
    margin: -3em;

    img {
      display: block;
      width: 100%;
      border-radius: 50%;
    }
  }
}

/*Lines conecting to the post it notes*/
.steg-ett::before {
  content: '';
  position: absolute;
  top: 2.9em;
  left: -10em;
  width: 10em;
  height: 0.2em;
  background: $greyLineColor;
}

.steg-tva::before {
  content: '';
  position: absolute;
  top: 2em;
  left: -10.02em;
  width: 10.16em;
  height: 0.2em;
  background: $greyLineColor;
}

.steg-tva::after {
  content: '';
  position: absolute;
  top: -0.6em;
  right: 16em;
  width: 0.2em;
  height: 2.8em;
  background: #979797;
}

.steg-tre::before {
  content: '';
  position: absolute;
  top: 2em;
  right: -9.02em;
  width: 9.16em;
  height: 0.2em;
  background: $greyLineColor;
}

.steg-fyra::before {
  content: '';
  position: absolute;
  top: 5.8em;
  right: 1.98em;
  width: 0.2em;
  height: 7.16em;
  background: $greyLineColor;
}

.steg-fyra::after {
  content: '';
  position: absolute;
  top: 12.8em;
  left: 3.8em;
  width: 6.16em;
  height: 0.2em;
  background: $greyLineColor;
}

.steg-fem::before {
  content: '';
  position: absolute;
  top: 5.8em;
  left: 3em;
  width: 0.2em;
  height: 5.16em;
  background: $greyLineColor;
}

.steg-trigger::before {
  content: '';
  top: -3em;
  left: -8em;
  position: absolute;
  bottom: -6.3em;
  width: 8em;
  height: 6.16em;
  border-bottom: 5px dotted #e05720;
}

.steg-trigger::after {
  content: '';
  top: 3em;
  left: -8em;
  position: absolute;
  width: 8em;
  height: 6.16em;
  border-left: 5px dotted #e05720;
}
// forms the child elements into a circle on the lined circle
.circle-container > :nth-child(4) {
  // steg 4
  transform: rotate(30deg) translate(12em) rotate(-30deg);
}
.circle-container > :nth-child(5) {
  //steg 5
  transform: rotate(90deg) translate(12em) rotate(-90deg);
}
.circle-container > :nth-child(6) {
  // trigger icon
  transform: rotate(150deg) translate(12em) rotate(-150deg);
}
.circle-container > :nth-child(1) {
  // steg 1
  transform: rotate(210deg) translate(12em) rotate(-210deg);
}
.circle-container > :nth-child(2) {
  // steg 2
  transform: rotate(270deg) translate(12em) rotate(-270deg);
}
.circle-container > :nth-child(3) {
  // steg 3
  transform: rotate(330deg) translate(12em) rotate(-330deg);
}
